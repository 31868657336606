const marineRadios = document.querySelectorAll('input[name="isThisAMarineRole"]');
const typeOfRole = document.getElementById('typeOfRole');

if (marineRadios) {
    marineRadios.forEach( (radio) => {
        radio.addEventListener('click', () => {
            console.log(radio.value);
            typeOfRole.value = radio.value;
        })
    })
}