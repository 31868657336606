import getBreakpoint from './breakpoints.js'
const header = document.querySelector('#header');
const navBtn = document.querySelector('#navBtn');
const navBtnText = navBtn.querySelector('.navBtnText');
const nav = document.querySelector('#nav');
const secondaryNavTriggers = document.querySelectorAll('.secondaryNavTrigger');
const eyebrow = document.querySelector('#eyebrow');
const body = document.querySelector('body');

if (navBtn) {

    navBtn.addEventListener('click', () => {
		// change aria-expanded on click
		let ariaExpanded = navBtn.getAttribute('aria-expanded');

		if (ariaExpanded === "true") {
			ariaExpanded = "false"
		} else {
			ariaExpanded = "true"
		}

		navBtn.setAttribute('aria-expanded', ariaExpanded);

        navBtn.classList.toggle('is-active');
        nav.classList.toggle('is-active');
		eyebrow.classList.toggle('is-active');
		header.classList.toggle('is-active');
		body.classList.toggle('is-fixed');

		if (navBtnText.innerHTML === 'Menu') {
			navBtnText.innerHTML = 'Close';
		} else {
			navBtnText.innerHTML = 'Menu';
		}
    })
}

if (secondaryNavTriggers) {

	secondaryNavTriggers.forEach( (item) => {
		item.addEventListener('click', () => {
			let breakpoint = getBreakpoint();

			if (breakpoint === 'thumb') {
				// change aria-expanded on click
				let ariaExpanded = item.getAttribute('aria-expanded');

				if (ariaExpanded === "true") {
					ariaExpanded = "false"
				} else {
					ariaExpanded = "true"
				}

				item.setAttribute('aria-expanded', ariaExpanded);

				item.classList.toggle('is-active');
				item.nextElementSibling.classList.toggle('is-active');
			}
		})
	});

	window.addEventListener('resize', () => {
		let breakpoint = getBreakpoint();

		if (breakpoint !== 'thumb') {
			setTimeout( () => {
				secondaryNavTriggers.forEach( (item) => {
					item.classList.remove('is-active');
					item.nextElementSibling.classList.remove('is-active');
				})
			}, 100)
		}
	})
}

function scrollingHeader() {
	if(window.pageYOffset <= 100) {
		if (header.classList.contains('is-scrolling')) {
			header.classList.remove('is-scrolling');
		}
	} else{
		if (!header.classList.contains('is-scrolling')) {
			header.classList.add('is-scrolling');
		}
	}
}

window.addEventListener('load', () => {
	scrollingHeader();
});

window.addEventListener('scroll', () => {
	scrollingHeader();
});