const Flickity = require('flickity');
const services = document.querySelectorAll('.servicesContainer');
const jobs = document.querySelectorAll('.jobsContainer');

if (services) {

	services.forEach( (item) => {

		const slider = item.querySelector('.servicesSlider');
		const sliderPrev = item.querySelector('.sliderPrev');
		const sliderNext = item.querySelector('.sliderNext');

		let flkty = new Flickity(slider, {
			accessibility: false,
			prevNextButtons: false,
			pageDots: false,
			imagesLoaded: true,
			cellAlign: 'left',
			// on: {
			// 	ready: function() {
			// 		setSliderHeightToMax(this);
			// 	}
			// }
		})

		sliderPrev.addEventListener('click', () => {
			flkty.previous();
		});

		sliderNext.addEventListener('click', () => {
			flkty.next();
		});

		window.addEventListener('load', () => {
			flkty.resize();
		});

		window.addEventListener('resize', () => {
			setSliderHeightToMax(flkty);
		});

		function setSliderHeightToMax(slider) {
			slider.cells.forEach(cell => cell.element.style.height = '');

			let heights = slider.cells.map(cell => cell.element.offsetHeight),
				max = Math.max.apply(Math, heights);

			slider.cells.forEach(cell => cell.element.style.height = max + 'px');
		}
	});
}

if (jobs) {

	jobs.forEach( (item) => {

		const slider = item.querySelector('.jobsSlider');
		const sliderPrev = item.querySelector('.sliderPrev');
		const sliderNext = item.querySelector('.sliderNext');

		let flkty = new Flickity(slider, {
			accessibility: false,
			prevNextButtons: false,
			pageDots: false,
			imagesLoaded: true,
			cellAlign: 'left',
			on: {
				ready: function() {
					setTimeout( () => {
						setSliderHeightToMax(this);
					}, 100);
				}
			}
		})

		sliderPrev.addEventListener('click', () => {
			flkty.previous();
		});

		sliderNext.addEventListener('click', () => {
			flkty.next();
		});

		window.addEventListener('load', () => {

			setTimeout( () => {
				flkty.resize();
			}, 300);
		});

		function setSliderHeightToMax(slider) {
			slider.cells.forEach(cell => cell.element.style.height = '');

			let heights = slider.cells.map(cell => cell.element.offsetHeight),
				max = Math.max.apply(Math, heights);

			slider.cells.forEach(cell => cell.element.style.height = max + 'px');
		}
	});
}

